<template>
  <div>

    <b-col cols="12">
      <b-card :title="$t('assinatura.titlePrimeiroCard')">
        <b-card-text>
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="mt-1"
            >
              <div class="mb-6">
                <h4 class="text-base font-weight-medium mb-1">
                  {{ $t('assinatura.subTitlePrimeiroCard') }}
                  <b-badge
                    variant="light-primary"
                  >
                    {{ assinaturas[0].variacao.nome }}
                  </b-badge>
                  <b-button
                    v-if="assinaturas[0].tipoCobranca=='CHECKOUT_INTERNO' || assinaturas[0].tipoCobranca=='CHECKOUT_INTERNO_TAXA_DESEMPENHO_PREPAGO'"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-modal.modal-plans
                    variant="primary"
                    size="sm"
                    class="ml-2"
                  >
                    {{ $t('assinatura.upgradeBtn') }}
                  </b-button>
                </h4>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="mt-1"
            >
              <div class="mb-6">
                <h4 class="text-base font-weight-medium mb-1">
                  <b-badge
                    :variant="assinaturas[0].status === 'Ativo' ? 'light-success' : 'light-danger'"
                  >
                    {{ assinaturas[0].status }}
                  </b-badge>
                  <template v-if="assinaturas[0].dataFim">
                    {{ $t('assinatura.textComplementoAte') }} {{ formattedDate(assinaturas[0].dataFim) }}
                  </template>
                </h4>
              </div>
            </b-col>
          </b-row>
        </b-card-text>
      </b-card>
    </b-col>

    <b-col
      v-if="assinaturas[0].tipoCobranca=='CHECKOUT_INTERNO' || assinaturas[0].tipoCobranca=='CHECKOUT_INTERNO_TAXA_DESEMPENHO_PREPAGO' && diasRestantes < 5"
      cols="12"
    >
      <b-card :title="$t('assinatura.titleSegundoCard')">
        <b-row>
          <b-alert
            color="warning"
            variant="warning"
            show
          >
            <div class="alert-body">
              <template v-if="diasRestantes >= 0">
                {{ $t('assinatura.alertTextAntesVencer') }}
                <strong v-if="diasRestantes > 0">{{ diasRestantes == 1 ? 'Resta '+diasRestantes+ ' dia': 'Restam '+diasRestantes+ ' dias' }}</strong>
              </template>
              <template v-else>
                <strong>{{ $t('assinatura.primeiroAlertTextVecimento') }}</strong> {{ $t('assinatura.segundoAlertTextVecimento') }}
              </template>
            </div>
          </b-alert>
        </b-row>
        <b-row
          v-if="assinaturas[0].tipoCobranca=='CHECKOUT_INTERNO' || assinaturas[0].tipoCobranca=='CHECKOUT_INTERNO_TAXA_DESEMPENHO_PREPAGO' && diasRestantes < 5"
          class="pricing-card mt-1"
        >
          <b-col
            sm="10"
            md="12"
            lg="10"
          >
            <h4 class="text-base font-weight-medium mb-2">
              {{ $t('assinatura.subTitleRecarga') }}
            </h4>
            <b-row>
              <b-col
                v-for="planoVar in planoVariacaoRecorrencia"
                :key="planoVar.id"
                md="3"
              >

                <b-card
                  align="center"
                >
                  <!-- img -->
                  <b-img
                    :src="require('@/assets/images/illustration/badge.svg')"
                    class="mb-2 mt-2"
                    sizes="smal"
                    alt="basic svg img"
                    height="50px"
                  />
                  <!--/ img -->
                  <h3>
                    Plan
                    <b-badge variant="warning">
                      {{ planoVar.nome }}
                    </b-badge>
                  </h3>

                  <!-- annual plan -->
                  <div class="annual-plan">
                    <div class="plan-price mt-2">
                      <sup class="font-medium-1 font-weight-bold text-primary">$</sup>
                      <span class="pricing-basic-value font-weight-bolder text-primary">{{ planoVar.precoFinal }}</span>
                    </div>
                  </div>
                  <!--/ annual plan -->
                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    block
                    class="mt-2"
                    variant="primary"
                    :to="{ name: 'checkout-recorrencia', params: { nomePlano: assinaturas[0].plano.nomeUrl, nomePlanoVariacao: planoVar.nomeUrl } }"
                  >
                    {{ $t('assinatura.recarregarBtn') }}
                  </b-button>

                </b-card>
              </b-col>

            </b-row>
          </b-col>
        </b-row>

      </b-card>
    </b-col>

    <b-col
      v-if="assinaturas[0].tipoCobranca=='CHECKOUT_INTERNO' || assinaturas[0].tipoCobranca=='CHECKOUT_INTERNO_TAXA_DESEMPENHO_PREPAGO'"
      cols="12"
    >
      <b-card :title="$t('assinatura.titleTerceiroCard')">
        <b-table-lite
          sticky-header="300px"
          hover
          responsive
          :items="assinaturas"
          :fields="['id','status', 'plano', 'valor', 'periodo', 'dataVencimento', 'dataPagamento']"
        >

          <template #cell(status)="data">
            <div class="text-nowrap">
              <b-badge variant="light-success">
                {{ data.item.pagamentos[0].status }}
              </b-badge>
            </div>
          </template>

          <template #cell(plano)="data">
            <div class="text-nowrap">
              {{ data.item.variacao.nome }}
            </div>
          </template>

          <template #cell(valor)="data">
            <div class="text-nowrap">
              ${{ data.item.pagamentos[0].valor }}
            </div>
          </template>

          <template #cell(periodo)="data">
            <div class="text-nowrap">
              {{ formattedDateMoment(data.item.dataInicio) }} - {{ formattedDateMoment(data.item.dataFim) }}
            </div>
          </template>

          <template #cell(dataVencimento)="data">
            {{ formattedDateMoment(data.item.pagamentos[0].dataVencimento) }}
          </template>

          <template #cell(dataPagamento)="data">
            {{ formattedDateTimeMoment(data.item.pagamentos[0].dataPagamento) }}
          </template>

        </b-table-lite>
      </b-card>
    </b-col>

    <b-modal
      v-if="planos && planos.length > 0"
      id="modal-plans"
      hide-footer
      scrollable
      size="xl"
    >

      <section id="pricing-plan">
        <div class="text-center">
          <h1 class="mt-5">
            {{ $t('assinatura.titlePrecoPlanos') }}
          </h1>
          <p class="mb-2 pb-75">
            {{ $t('assinatura.textPrecoPlanos') }}
          </p>
        </div>
        <b-row
          md="4"
          class="pricing-card"
        >
          <b-col
            offset-sm-2
            sm="10"
            md="12"
            offset-lg="2"
            lg="10"
            class="mx-auto"
          >
            <b-row
              v-for="plano in planos"
              :key="plano.id"
              class="d-flex justify-content-center"
            >
              <b-col
                v-for="planoVar in planVariacaoUpgrade(plano)"
                :key="planoVar.id"
                md="3"
              >
                <b-card
                  align="center"
                >
                  <div
                    v-show="planoVar && planoVar.popular"
                    class="pricing-badge text-right"
                  >
                    <b-badge
                      variant="light-primary"
                      pill
                    >
                      {{ $t('assinatura.textBadgePrecoPopular') }}
                    </b-badge>
                  </div>

                  <!-- img -->
                  <b-img
                    :src="require('@/assets/images/illustration/badge.svg')"
                    class="mb-2 mt-5"
                    sizes="smal"
                    alt="basic svg img"
                    height="50px"
                  />
                  <!--/ img -->
                  <h3>
                    {{ plano.nome }}
                    <b-badge variant="warning">
                      {{ planoVar.nome }}
                    </b-badge>
                  </h3>

                  <!-- annual plan -->
                  <div class="annual-plan">
                    <div class="plan-price mt-2">
                      <sup class="font-medium-1 font-weight-bold text-primary">$</sup>
                      <span class="pricing-basic-value font-weight-bolder text-primary">{{ planoVar.precoFinal }}</span>
                      <div class="pricing-duration text-body font-small-3 font-weight-bold mt-1">
                        (
                        {{ planoVar.preco }} {{ $t('ativacao.licencia') }}
                        <template v-if="planoVar.creditoTaxaDesempenho > 0">
                          + {{ planoVar.creditoTaxaDesempenho }} {{ $t('ativacao.creditoTaxaDesempenho') }}
                        </template>
                        )
                      </div>
                    </div>
                  </div>
                  <!--/ annual plan -->
                  <b-list-group class="list-group-circle text-left">
                    <b-list-group-item
                      v-for="(data,index) in planoVar.beneficio"
                      :key="index"
                    >
                      {{ data }}
                    </b-list-group-item>
                  </b-list-group>

                  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    block
                    class="mt-2"
                    :disabled="temAssinatura(planoVar.id)"
                    :variant="temAssinatura(planoVar.id)? 'outline-success': 'primary'"
                    :to="{ name: 'checkout-upgrade', params: { nomePlano: plano.nomeUrl, nomePlanoVariacao: planoVar.nomeUrl } }"
                  >
                    {{ temAssinatura(planoVar.id)? 'Tu plan actual': parseFloat(planoVar.preco) > parseFloat(assinaturas[0].variacao.preco)? 'Upgrade': 'Downgrade' }}
                  </b-button>

                </b-card>
              </b-col>

            </b-row>

          </b-col>
        </b-row>
      </section>
    </b-modal>

  </div>

</template>

<script>
import {
  BCard, BBadge, VBToggle, BRow, BCol, BImg, BListGroup, BListGroupItem, BCardText, BButton, BModal, VBModal, BTableLite, BAlert,
} from 'bootstrap-vue'

import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { formatDateBR, formatDateTimeBR, formatDateSemDiaBR } from '@/utils/filter'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import moment from 'moment'
import assinaturaStoreModule from './assinaturaStoreModule'

const CONTA_ASSINATURA_STORE_MODULE_NAME = 'conta-assinatura'

export default {

  components: {
    BCard,
    BBadge,
    BRow,
    BCol,
    BImg,
    BListGroup,
    BListGroupItem,
    BCardText,
    BButton,
    BModal,
    BTableLite,
    BAlert,
  },

  directives: {
    'b-modal': VBModal,
    Ripple,
    'b-toggle': VBToggle,
  },

  data() {
    return {
      assinaturas: [],
      planos: [],
      planoVariacaoRecorrencia: [],
      pagamento: {
        forma: null,
        id: null,
      },
      formatDateBR,
      formatDateTimeBR,
      formatDateSemDiaBR,
    }
  },

  computed: {

    totalDias() {
      return this.calculateDaysDifference(this.assinaturas[0].dataInicio, this.assinaturas[0].dataFim)
    },

    diasRestantes() {
      if (this.assinaturas[0] && this.assinaturas[0].dataFim) {
        return this.calculateDaysDifference(new Date(), this.assinaturas[0].dataFim)
      }
      return 0
    },
  },

  created() {
    if (!store.hasModule(CONTA_ASSINATURA_STORE_MODULE_NAME)) store.registerModule(CONTA_ASSINATURA_STORE_MODULE_NAME, assinaturaStoreModule)
    this.getPlanos()
    this.getAssinatura()
  },

  beforeDestroy() {
    if (store.hasModule(CONTA_ASSINATURA_STORE_MODULE_NAME)) store.unregisterModule(CONTA_ASSINATURA_STORE_MODULE_NAME)
  },

  methods: {

    planVariacaoUpgrade(plano) {
      return plano.planoVariacao.filter(planoVar => !planoVar.recorrenciaDesabilitada)
    },

    temAssinatura(id) {
      // Verifica se existe alguma assinatura com variacao.id igual a id
      return this.assinaturas.some(assinatura => assinatura.variacao.id === id && assinatura.status === 'Ativo')
    },

    formattedDate(originalDate) {
      // eslint-disable-next-line global-require
      const dateObject = new Date(originalDate)
      return format(dateObject, 'dd MMMM \'de\' yyyy', { locale: ptBR })
    },

    formattedDateMoment(originalDate) {
      return moment(originalDate).format('DD/MM/YYYY')
    },

    formattedDateTimeMoment(originalDate) {
      return moment(originalDate).format('DD/MM/YYYY HH:mm:ss')
    },

    calculateDaysRemaining(endDate) {
      const today = new Date()
      const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
      const daysRemaining = Math.round(Math.abs((today - new Date(endDate)) / oneDay))

      return daysRemaining
    },

    calculateDaysDifference(startDateStr, endDateStr) {
      const startDate = new Date(startDateStr)
      const endDate = new Date(endDateStr)
      // Calcula a diferença em milissegundos
      const timeDifference = endDate - startDate
      // Converte a diferença de milissegundos para dias
      const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24))

      return daysDifference
    },

    getAssinatura() {
      store
        .dispatch('conta-assinatura/getAssinatura')
        .then(response => {
          this.assinaturas = response.data
          if (response.data[0].tipoCobranca === 'CHECKOUT_INTERNO' || response.data[0].tipoCobranca === 'CHECKOUT_INTERNO_TAXA_DESEMPENHO_PREPAGO') {
            this.getPlanoRecorrencia(response.data[0].plano.nomeUrl)
          }
        })
    },
    getPlanos() {
      store
        .dispatch('conta-assinatura/getPlanos')
        .then(response => {
          this.planos = response.data
        })
    },
    getPlanoRecorrencia(nomePlano) {
      store
        .dispatch('conta-assinatura/getPlanoRecorrencia', { nomePlano })
        .then(response => {
          this.planoVariacaoRecorrencia = response.data
        })
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-pricing.scss';
</style>
